import { ChainId } from '@fatex-dao/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BSC_MAINNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.BSC_TESTNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.HARMONY_MAINNET]: '0x41Fec4E5De930d8a618900973f0A678114C27361',
  [ChainId.HARMONY_TESTNET]: '0xE3CaC535fcb777A42Dc2C234E0a8884049A4264e',
  [ChainId.POLYGON_MAINNET]: '0xfE2Adcf4039A481a6f92cbD2e60F476eb20B4cc1'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
